import Vue from "vue";
import config from "@/config";

const notify = {};
const base = config.api_base + "/system/notify"

notify.sysNoticeOne = (params)=>Vue.axios.post( base + "/sys-notice-one",params);
notify.sysNoticeSearch = (params)=>Vue.axios.post( base + "/sys-notice-search",params);
notify.sysNoticeEdit = (params)=>Vue.axios.post( base + "/sys-notice-edit",params);
notify.sysNoticeDel = (params)=>Vue.axios.post( base + "/sys-notice-del",params);

export default notify;