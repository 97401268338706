import chain from "@/router/addons/chain";
import area_agent from "@/router/addons/area_agent";
import knowledge from "@/router/addons/knowledge";
export default [
    {
        path: "/addons",
        component: () => import("@/view/addons/index"),
        redirect: "/addons/info",
        name: "addons",
        meta: {
            title: "应用"
        },
        children: [
            {
                path: "info",
                name: "addonsInfo",
                component: () => import("@/view/addons/info"),
                meta: {
                    title: "应用中心"
                }
            },
            ...chain,
            ...area_agent,
            ...knowledge,
        ]
    }
]