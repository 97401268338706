import store from "@/store";
import Vue from "vue";
import config from "@/config";

let account = {}
const base = config.api_base + "/sys/account"
account.login = (data) => {
    return new Promise((resolve, reject) => {
        Vue.axios.post(base + "/login", data).then(res => {
            store.commit("sys/account/tokenSet", res.token);
            store.commit("sys/account/infoSet", res.info);
            store.commit("sys/menu/set", res);
            store.commit("sys/account/rememberSet", data);
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}
account.info = () => Vue.axios.get(base + "/info");
account.resetPassword = (params) => Vue.axios.post(base + "/reset-password", params);
account.menuInfo = (params) => Vue.axios.post(base + "/menu-info", params);

export default account