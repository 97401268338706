export default [
    {
        path:"/",
        component:()=>import("@/view/sys/login"),
        name:"login",
        meta:{
            title:"登陆"
        }
    },
    {
        path: "/forget",
        component: ()=>import("@/view/sys/forget"),
        name:"forget",
        meta:{
            title:"忘记密码"
        }
    },
    {
        path:"/account",
        name: "accountInfo",
        meta:{
            title:"个人账号"
        },
        component:()=>import("@/view/sys/account/info")
    }
]