import api from "@/api";
// import router from "@/router";
const tokenKey = "yshoptoken"
const account = {
    namespaced:true,
    state:()=>({
        token:"",
        info:{
            is_founder:false,
            username:"",
            status:"",
            remark:"",
            uni_acid:0,
        },
        remember:{
            a:"",
            p:"",
        }
    }),
    mutations:{
        rememberSet(state, data){
            state.remember.a = data.username;
            state.remember.p = data.password;
            localStorage.removeItem("y_remember")
            localStorage.setItem("y_remember",JSON.stringify(state.remember))
        },
        tokenSet(state,token){
            state.token = token;
            localStorage.removeItem(tokenKey)
            localStorage.setItem(tokenKey,token)
        },
        infoSet(state,data){
            state.info = data;
        }
    },
    actions:{
        remember(context) {
            if (!context.state.remember.a){
                let local = localStorage.getItem("y_remember");
                if(local){
                    let r = JSON.parse(local)
                    if (local)context.commit("rememberSet", {username:r.a, password:r.p});
                    return r || false;
                }
                return false
            }
            return context.state.remember;
        },
        token(context){
            if (!context.state.token){
                let local = localStorage.getItem(tokenKey);
                if (local)context.commit("tokenSet",local);
                return local || "";
            }
            return context.state.token;
        },
        loadInfo(context){
            if(context.state.info.username) {
                return context.state.info;
            }
            return new Promise((resolve, reject) => {
                api.sys.account.info().then(res=>{
                    context.commit("infoSet",res);
                    resolve(res);
                }).catch(err=>reject(err));
            })
        },
        reloadInfo(context){
            return new Promise((resolve, reject) => {
                api.sys.account.info().then(res=>{
                    context.commit("infoSet",res);
                    resolve(res);
                }).catch(err=>reject(err));
            })
        },
        logout(context){
            localStorage.removeItem(tokenKey);
            context.commit("rememberSet",{username:"", password:""})
            context.commit("tokenSet","");
            context.commit("infoSet",{
                username:"",
                status:0,
                status_str:""
            });
            // router.toLoginPage();
            location.href = location.origin
        }
    }
}
export default account;