export default [
    {
        path:"/manager",
        component:()=>import("@/view/sys/manager/index"),
        redirect:"/manager/sys",
        name:"manager",
        meta: {
            title:"站点管理"
        },
        children:[
            {
                path:"account",
                name:"managerAccount",
                component:()=>import("@/view/sys/manager/account"),
                meta:{
                    title:"账号管理"
                }
            },
            {
                path:"uni",
                name:"managerUni",
                component:()=>import("@/view/sys/manager/uni"),
                meta:{
                    title:"平台管理"
                }
            },
            {
                path:"attachment",
                name:"managerAttachment",
                component:()=>import("@/view/sys/manager/attachment"),
                meta:{
                    title:"附件管理"
                }
            },
            {
                path:"site",
                name:"managerSite",
                component:()=>import("@/view/sys/manager/site"),
                meta:{
                    title:"站点设置"
                }
            },
            {
                path:"sys",
                name:"managerSys",
                component:()=>import("@/view/sys/manager/sys"),
                meta:{
                    title:"系统授权"
                }
            },
            {
                path:"wx-official",
                name:"managerWxOfficial",
                component:()=>import("@/view/sys/manager/wxOfficial"),
                meta:{
                    title:"微信公众号"
                }
            },
            {
                path:"wx-pay",
                name:"managerWxPay",
                component:()=>import("@/view/sys/manager/wxPay"),
                meta:{
                    title:"微信支付"
                }
            },
            {
                path:"ali-pay",
                name:"managerAliPay",
                component:()=>import("@/view/sys/manager/aliPay"),
                meta:{
                    title:"支付宝支付"
                }
            },
            {
                path:"sns",
                name:"managerSns",
                component:()=>import("@/view/sys/manager/sns"),
                meta:{
                    title:"短信配置"
                }
            },
        ]
    }
]