export default [
    {
        path: "area-agent",
        component: () => import("@/view/addons/area_agent/index"),
        redirect: "/addons/area-agent/set",
        name: "addonsAreaAgent",
        meta: {
            title: "区域代理模式"
        },
        children: [
            {
                path: "up-agent",
                name: "addonsAreaAgentUpAgent",
                component: () => import("@/view/addons/area_agent/upAgent"),
                meta: {
                    title: "会员专区"
                }
            },
            {
                path: "u-agent",
                name: "addonsAreaAgentUAgent",
                component: () => import("@/view/addons/area_agent/uAgent"),
                meta: {
                    title: "会员"
                }
            },
            {
                path: "up-street",
                name: "addonsAreaAgentUpStreet",
                component: () => import("@/view/addons/area_agent/upStreet"),
                meta: {
                    title: "乡镇代理专区"
                }
            },
            {
                path: "u-street",
                name: "addonsAreaAgentUStreet",
                component: () => import("@/view/addons/area_agent/uStreet"),
                meta: {
                    title: "乡镇代理"
                }
            },
            {
                path: "up-district",
                name: "addonsAreaAgentUpDistrict",
                component: () => import("@/view/addons/area_agent/upDistrict"),
                meta: {
                    title: "区县代理专区"
                }
            },
            {
                path: "u-district",
                name: "addonsAreaAgentUDistrict",
                component: () => import("@/view/addons/area_agent/uDistrict"),
                meta: {
                    title: "区县代理"
                }
            },
            {
                path: "up-city",
                name: "addonsAreaAgentUpCity",
                component: () => import("@/view/addons/area_agent/upCity"),
                meta: {
                    title: "市级代理专区"
                }
            },
            {
                path: "u-city",
                name: "addonsAreaAgentUCity",
                component: () => import("@/view/addons/area_agent/uCity"),
                meta: {
                    title: "市级代理"
                }
            },
            {
                path: "up-province",
                name: "addonsAreaAgentUpProvince",
                component: () => import("@/view/addons/area_agent/upProvince"),
                meta: {
                    title: "省级代理专区"
                }
            },
            {
                path: "u-province",
                name: "addonsAreaAgentUProvince",
                component: () => import("@/view/addons/area_agent/uProvince"),
                meta: {
                    title: "省级代理"
                }
            },
            {
                path: "partner",
                name: "addonsAreaAgentPartner",
                component: () => import("@/view/addons/area_agent/partner"),
                meta: {
                    title: "合伙人"
                }
            },
            {
                path: "set",
                name: "addonsAreaAgentSet",
                component: () => import("@/view/addons/area_agent/set"),
                meta: {
                    title: "规则配置"
                }
            },
        ]
    }
]