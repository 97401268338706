import Vue from "vue";
import config from "@/config";

const chain = {};
const base = config.api_base + "/addons/chain"

chain.goodsThemeSet = (params)=>Vue.axios.post( base + "/goods-theme-set",params);
chain.goodsThemeRemove = (params)=>Vue.axios.post( base + "/goods-theme-remove",params);

chain.rule = (params)=>Vue.axios.post( base + "/rule",params);
chain.ruleEdit = (params)=>Vue.axios.post( base + "/rule-edit",params);


chain.searchUser = (params)=>Vue.axios.post( base + "/search-user",params);
chain.findUser = (params)=>Vue.axios.post( base + "/find-user",params);
chain.findTeamUser = (params)=>Vue.axios.post( base + "/find-team-user",params);

chain.chiefApply = (params)=>Vue.axios.post( base + "/chief-apply",params);
chain.chiefApplyDose = (params)=>Vue.axios.post( base + "/chief-apply-dose",params);


export default chain;