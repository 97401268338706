import sys from "@/api/sys"
import shop from "@/api/shop"
import system from "@/api/system";
import addons from "@/api/addons";
import member from "@/api/member";
export default {
    sys,
    shop,
    system,
    addons,
    member
}

export function requestWithUni(path){
    return path + "?" + getUrlByParam({i:getUniacid()})
}

export function getUniacid(){
    let getI = getParam()?.['i']
    return getI ? parseInt(getI) : 0;
}

function getUrlByParam(param) {
    let url = "";
    let fields = Object.keys(param)
    fields.forEach(item=>{
        url += "&" + item + "=" + param[item];
    })
    return !url ? url : url.substring(1);
}

function getParam() {
    let param = {};
    if (location.search === "") {
        return param;
    }
    let query = location.search.substring(1, location.search.lastIndexOf("/") === -1 ? location.search.length :location.search.length-1);
    let list = query.split('&');
    let item = [];
    list.forEach(raw=>{
        item = raw.split("=")
        param[item[0]] = item[1]
    })
    return param;
}