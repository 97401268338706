import Vue from "vue";
import config from "@/config";

const info = {};
const base = config.api_base + "/system/info"

info.sysInfo = (params)=>Vue.axios.post( base + "/sys-info",params);
info.sysInfoEdit = (params)=>Vue.axios.post( base + "/sys-info-edit",params);

info.sysPoster = (params)=>Vue.axios.post( base + "/sys-poster",params);
info.sysPosterEdit = (params)=>Vue.axios.post( base + "/sys-poster-edit",params);
info.sysPosterClear = (params)=>Vue.axios.post( base + "/sys-poster-clear",params);


info.wxOfficial = (params)=>Vue.axios.post( base + "/wx-official",params);
info.wxOfficialEdit = (params)=>Vue.axios.post( base + "/wx-official-edit",params);

info.wxappUpload = (params)=>Vue.axios.post( base + "/wxapp-upload",params);
info.wxappInfo = (params)=>Vue.axios.post( base + "/wxapp-info",params);
info.wxappEdit = (params)=>Vue.axios.post( base + "/wxapp-edit",params);

info.wxPay = (params)=>Vue.axios.post( base + "/wx-pay",params);
info.wxPayEdit = (params)=>Vue.axios.post( base + "/wx-pay-edit",params);
info.wxPayCert = (params)=>Vue.axios.post( base + "/wx-pay-cert",params);

info.aliPay = (params)=>Vue.axios.post( base + "/ali-pay",params);
info.aliPayEdit = (params)=>Vue.axios.post( base + "/ali-pay-edit",params);
info.aliPayCert = (params)=>Vue.axios.post( base + "/ali-pay-cert",params);

info.sns = (params)=>Vue.axios.post( base + "/sns",params);
info.snsEdit = (params)=>Vue.axios.post( base + "/sns-edit",params);

export default info;