import Vue from "vue";
import config from "@/config";

const cash = {};
const base = config.api_base + "/member/cash"

cash.search = (params)=>Vue.axios.post( base + "/search",params);
cash.pay = (params)=>Vue.axios.post( base + "/pay",params);
cash.fail = (params)=>Vue.axios.post( base + "/fail",params);
cash.accountsByUid = (params)=>Vue.axios.post( base + "/accounts-by-uid",params);
cash.awardLog = (params)=>Vue.axios.post( base + "/award-log",params);

export default cash;