export default [
    {
        path: "/finance",
        component: () => import("@/view/finance/index"),
        redirect: "/finance/wait",
        name: "finance",
        meta: {
            title: "财务"
        },
        children: [
            {
                path: "wait",
                name: "financeWait",
                component: () => import("@/view/finance/wait"),
                meta: {
                    title: "待打款"
                }
            },
            {
                path: "log",
                name: "financeLog",
                component: () => import("@/view/finance/log"),
                meta: {
                    title: "打款记录"
                }
            }
        ]
    }
]