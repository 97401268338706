export default [
    {
        path: "/order",
        component: () => import("@/view/order/index"),
        redirect: "/order/manage",
        name: "order",
        meta: {
            title: "订单"
        },
        children: [
            {
                path: "manage",
                name: "orderManage",
                component: () => import("@/view/order/manage"),
                meta: {
                    title: "订单管理"
                }
            }
        ]
    }
]