export default [
    {
        path: "/member",
        component: () => import("@/view/member/index"),
        redirect: "/member/manage",
        name: "member",
        meta: {
            title: "用户"
        },
        children: [
            {
                path: "manage",
                name: "memberManage",
                component: () => import("@/view/member/manage"),
                meta: {
                    title: "用户管理"
                }
            }
        ]
    }
]