import VueRouter from 'vue-router';
import store from "@/store";
import {Message} from "element-ui";
import sys from "@/router/sys";
import goods from "@/router/goods";
import order from "@/router/order";
import finance from "@/router/finance";
import member from "@/router/member";
import system from "@/router/system";
import addons from "@/router/addons";
const routes = [
    {
        path: "/home",
        component: () => import("@/view/home/index"),
        name: "home",
        meta: {
            title: "概览"
        }
    },
    ...sys,
    ...goods,
    ...order,
    ...finance,
    ...member,
    ...system,
    ...addons,
]
const router = new VueRouter({
    history: "hash",
    routes: routes,
});
router.beforeEach(async (to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    if (!to.name || !["login", "register", "forget"].includes(to.name)) {
        if (!await store.dispatch("sys/account/token")) {
            Message.error("请先登陆");
            next({
                path: "/",
                query: {
                    redirect: to.fullPath
                }
            })
            return;
        }
    }
    if (!["login", "register", "forget"].includes(to.name)) {
        let accountInfo = await store.dispatch("sys/account/loadInfo");
        if (to.path !== "/account/info" && accountInfo.status !== 1) {
            Message({message: accountInfo.status_str, type: "error"})
            if (from.path === "/account/info") {
                next(false);
                return;
            }
            next({
                path: "/account/info",
            })
            return
        }
    }
    next();
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

router.toLoginPage = () => {
    router.push({
        name: "login"
    });
}
router.toHomePage = () => {
    router.push({
        name:"home"
    });
}
router.toAccountPage = () => {
    router.push({
        name:"accountInfo"
    });
}
router.toManagerPage = () => {
    router.push({
        name:"manager"
    })
}

export default router


