export default [
    {
        path: "chain",
        component: () => import("@/view/addons/chain/index"),
        redirect: "/addons/chain/upgrade",
        name: "addonsChain",
        meta: {
            title: "2+1链动模式"
        },
        children: [
            {
                path: "upgrade",
                name: "addonsChainUpgrade",
                component: () => import("@/view/addons/chain/upgrade"),
                meta: {
                    title: "加盟专区"
                }
            },
            {
                path: "free",
                name: "addonsChainFree",
                component: () => import("@/view/addons/chain/free"),
                meta: {
                    title: "免费专区"
                }
            },
            {
                path: "set",
                name: "addonsChainSet",
                component: () => import("@/view/addons/chain/set"),
                meta: {
                    title: "规则配置"
                }
            },
            {
                path: "member",
                name: "addonsChainMember",
                component: () => import("@/view/addons/chain/member"),
                meta: {
                    title: "会员"
                }
            },
            {
                path: "agent",
                name: "addonsChainAgent",
                component: () => import("@/view/addons/chain/agent"),
                meta: {
                    title: "代理"
                }
            },
            {
                path: "boos",
                name: "addonsChainBoos",
                component: () => import("@/view/addons/chain/boos"),
                meta: {
                    title: "老板"
                }
            },
            {
                path: "chief",
                name: "addonsChainChief",
                component: () => import("@/view/addons/chain/chief"),
                meta: {
                    title: "总监"
                }
            }
        ]
    }
]