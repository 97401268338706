import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'

import axios from '@/api/axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);
import api from "@/api";

Vue.prototype.$api = api;

import store from "@/store"


import VueRouter from 'vue-router';

Vue.use(VueRouter);
import router from "@/router";

import * as echarts from 'echarts';

Vue.prototype.$echarts = echarts;

import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);

Vue.config.productionTip = false

Vue.filter("tomedia", (value) => {
    if (!value) return '';
    if (value.includes("http")) return value;
    return store.state.sys.global.attachment_url + value;
})
Vue.filter("date", (value) => {
    if (!value) return "";
    let date = new Date(value);
    if (date.getTime() < 0) return "";
    const YY = date.getFullYear() + '-';
    const MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    const DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
    const hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    const mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    const ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return YY + MM + DD + " " + hh + mm + ss;
})

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')

store.dispatch("sys/global/register");
