import axios from "axios";
import {Message} from 'element-ui';
import router from "@/router";
import store from "@/store";
import config from "@/config";
import {requestWithUni} from "@/api/index";
import api from "@/api";


axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? location.origin : config.dev_base;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

axios.interceptors.request.use(async function (config) {
    config.url = requestWithUni(config.url)
    config.headers.ytoken = await store.dispatch("sys/account/token")
    return config;
}, function (error) {
    Message.error("网络通信异常");
    return Promise.reject(error)
})

axios.interceptors.response.use(async function (response) {
    if (response.data.errno !== 0) {
        if (response.data.message && response.data.errno !== 40019) Message.error(response.data.message);
        if (response.data.errno === 2 && response.data.data) {
            if (router.currentRoute.path !== response.data.data) {
                router.push(response.data.data);
            }
        } else if (response.data.errno === 40019) {
            let  r = await store.dispatch("sys/account/remember")
            if (r.a && r.p){
                api.sys.account.login({
                    username:r.a,
                    password:r.p,
                }).then(()=>{
                    location.reload();
                }).catch(()=>{
                    router.toLoginPage();
                });
            }else {
                router.toLoginPage();
            }
        }
        return Promise.reject(response.data);
    }
    return response.data.data;
}, function (error) {
    Message.error("响应异常");
    return Promise.reject(error)
})

export default axios