import Vue from 'vue';
import Vuex from "vuex";

Vue.use(Vuex);
import sys from "@/store/sys";
const store = new Vuex.Store({
    modules:{
        sys,
    }
})

export default store;