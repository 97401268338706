import Vue from "vue";
import config from "@/config";

const page = {};
const base = config.api_base + "/system/page"

page.bannerAll = (params)=>Vue.axios.post( base + "/banner-all",params);
page.bannerEdit = (params)=>Vue.axios.post( base + "/banner-edit",params);
page.bannerDel = (params)=>Vue.axios.post( base + "/banner-del",params);

page.girdAll = (params)=>Vue.axios.post( base + "/gird-all",params);
page.girdEdit = (params)=>Vue.axios.post( base + "/gird-edit",params);
page.girdDel = (params)=>Vue.axios.post( base + "/gird-del",params);

export default page;