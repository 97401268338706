import Vue from "vue";
import config from "@/config";

const manager = {};
const base = config.api_base + "/sys/manager"

manager.uniSearch = (params)=>Vue.axios.post( base + "/uni-search",params);
manager.uniEdit = (params)=>Vue.axios.post( base + "/uni-edit",params);
manager.uniTotal = (params)=>Vue.axios.post( base + "/uni-total",params);
manager.uniDel = (params)=>Vue.axios.post( base + "/uni-del",params);

manager.accountSearch = (params)=>Vue.axios.post( base + "/account-search",params);
manager.accountResetPassword = (params)=>Vue.axios.post( base + "/account-reset-password",params);
manager.accountDel = (params)=>Vue.axios.post( base + "/account-del",params);
manager.accountAdd = (params)=>Vue.axios.post( base + "/account-add",params);


manager.systemFetch = (params)=>Vue.axios.post( base + "/system-fetch",params);
manager.systemEdit = (params)=>Vue.axios.post( base + "/system-edit",params);


manager.attachRemoteOpt = (params)=>Vue.axios.post( base + "/attach-remote-opt",params);
manager.attachRemoteOptEdit = (params)=>Vue.axios.post( base + "/attach-remote-opt-edit",params);
manager.attachRemoteTest = (params)=>Vue.axios.post( base + "/attach-remote-test",params);
manager.attachAllLocal2Remote = (params)=>Vue.axios.post( base + "/attach-all-local2remote",params);

manager.versionRemote = (params)=>Vue.axios.post( base + "/version-remote",params);
manager.versionUpdate = (params)=>Vue.axios.post( base + "/version-update",params);


manager.wxOfficial = (params)=>Vue.axios.post( base + "/wx-official",params);
manager.wxOfficialEdit = (params)=>Vue.axios.post( base + "/wx-official-edit",params);

manager.wxPay = (params)=>Vue.axios.post( base + "/wx-pay",params);
manager.wxPayEdit = (params)=>Vue.axios.post( base + "/wx-pay-edit",params);
manager.wxPayCert = (params)=>Vue.axios.post( base + "/wx-pay-cert",params);

manager.aliPay = (params)=>Vue.axios.post( base + "/ali-pay",params);
manager.aliPayEdit = (params)=>Vue.axios.post( base + "/ali-pay-edit",params);
manager.aliPayCert = (params)=>Vue.axios.post( base + "/ali-pay-cert",params);

manager.sns = (params)=>Vue.axios.post( base + "/sns",params);
manager.snsEdit = (params)=>Vue.axios.post( base + "/sns-edit",params);

export default manager;