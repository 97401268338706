export default [
    {
        path: "/goods",
        component: () => import("@/view/goods/index"),
        redirect: "/goods/manage",
        name: "goods",
        meta: {
            title: "商品库"
        },
        children: [
            {
                path: "manage",
                name: "goodsManage",
                component: () => import("@/view/goods/manage"),
                meta: {
                    title: "商品管理"
                }
            },
            {
                path: "edit",
                name: "goodsEdit",
                component: () => import("@/view/goods/edit"),
                meta: {
                    title: "商品编辑"
                }
            },
            {
                path: "category",
                name: "goodsCategory",
                component: () => import("@/view/goods/category"),
                meta: {
                    title: "分类管理"
                }
            },
            {
                path:"theme",
                name: "goodsTheme",
                component: ()=>import("@/view/goods/theme/list"),
                meta: {
                    title: "商品专题"
                }
            },
            {
                path:"shipping",
                name: "goodsShipping",
                component: ()=>import("@/view/goods/shipping/list"),
                meta: {
                    title: "运费模版"
                }
            },
            {
                path:"shipping-items",
                name: "goodsShippingItems",
                component: ()=>import("@/view/goods/shipping/edit"),
                meta: {
                    title: "运费模版编辑"
                }
            },
            {
                path:"import",
                name: "goodsImport",
                component: ()=>import("@/view/goods/import"),
                meta: {
                    title: "快速导入淘宝商品"
                }
            },
        ]
    }
]