import api from "@/api";

const menu = {
    namespaced:true,
    state:()=>({
        home_page:"",
        list:[]
    }),
    mutations:{
        set(state,data){
            state.home_page = data.home_page;
            state.list = data.list;
        }
    },
    actions:{
        load(context){
            return new Promise((resolve)=>{
                if (!context.state.home_page){
                    api.sys.account.menuInfo().then(res=>{
                        context.commit("set",res);
                        resolve(res);
                    })
                    return;
                }
                resolve(context.state);
            })
        },
        reload(context){
            api.sys.account.menuInfo().then(res=>{
                context.commit("set",res);
            })
        }
    }
}

export default menu;