import global from "@/store/sys/global";
import account from "@/store/sys/account";
import menu from "@/store/sys/menu";
const sys = {
    namespaced: true,
    modules: {
        global,
        account,
        menu
    }
}

export default sys