import conf from "@/config";
import api from "@/api";
let global_conf = {
    production: {
        attachment_url: document.head.querySelector("[name=attachment_url][content]").content,
        site_root: document.head.querySelector("[name=site_root][content]").content,
        site_logo: document.head.querySelector("[name=site_logo][content]").content,
        login: {
            banner: document.head.querySelector("[name=login_banner][content]").content,
            bg_img: document.head.querySelector("[name=login_bg_img][content]").content,
            title: document.head.querySelector("[name=login_title][content]").content,
        },
        copyright: document.head.querySelector("[name=copyright][content]").content,
        icp: document.head.querySelector("[name=icp][content]").content,
    },
    development: {
        attachment_url: `${conf.dev_attachment_url}`,
        site_root: `${conf.dev_base}/`,
        site_logo: "",
        login: {
            banner: "",
            bg_img: "https://img.zcool.cn/community/017dc261af4eeb11013f01cd49e4cb.jpg@260w_195h_1c_1e_1o_100sh.jpg",
            title: "",
        },
        copyright: "这是版权",
        icp: "这是icp",
    }
}

const global = {
    namespaced: true,
    state: () => ({
        attachment_url: "",
        site_root: "",
        site_logo: "",
        login: {
            banner: "",
            bg_img: "",
            title: "",
        },
        copyright: "",
        icp: "",
        addons:{
            chain:false,
            area_agent:false,
        }
    }),
    mutations: {
        set(state, data) {
            state.attachment_url = data.attachment_url;
            state.site_root = data.site_root;
            state.site_logo = data.site_logo;
            state.copyright = data.copyright;
            state.icp = data.icp;
            state.login.banner = data.login.banner;
            state.login.bg_img = data.login.bg_img;
            state.login.title = data.login.title;
        },
        setAddons(state,data){
            state.addons = data
        }
    },
    actions: {
        register(ctx) {
            ctx.commit("set", global_conf[process.env.NODE_ENV]);
            if (location.hash !== "#/"){
                api.addons.basic.info().then(res=>{
                    ctx.commit("setAddons",res)
                })
            }
        }
    }
}
export default global;