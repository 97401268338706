import Vue from "vue";
import config from "@/config";

const account = {};
const base = config.api_base + "/member/account"

account.search = (params)=>Vue.axios.post( base + "/search",params);
account.one = (params)=>Vue.axios.post( base + "/one",params);
account.selLevel = (params)=>Vue.axios.post( base + "/sel-level",params);
account.selInviteU = (params)=>Vue.axios.post( base + "/sel-invite-u",params);
account.handsRegister = (params)=>Vue.axios.post( base + "/hands-register",params);

export default account;