export default [
    {
        path: "/system",
        component: () => import("@/view/system/index"),
        redirect: "/system/sys-notice",
        name: "system",
        meta: {
            title: "系统"
        },
        children: [
            {
                path: "entry",
                name: "systemEntry",
                component: () => import("@/view/system/entry"),
                meta: {
                    title: "入口链接"
                }
            },
            {
                path: "info",
                name: "systemInfo",
                component: () => import("@/view/system/info"),
                meta: {
                    title: "系统配置"
                }
            },
            {
                path: "poster",
                name: "systemPoster",
                component: () => import("@/view/system/poster"),
                meta: {
                    title: "海报配置"
                }
            },
            {
                path: "banner",
                name: "systemBanner",
                component: () => import("@/view/system/page/banner"),
                meta: {
                    title: "首页轮播图"
                }
            },
            {
                path: "gird",
                name: "systemGird",
                component: () => import("@/view/system/page/gird"),
                meta: {
                    title: "首页宫格"
                }
            },


            {
                path: "sys-notice",
                name: "systemSysNotice",
                component: () => import("@/view/system/notify/sysNotice/list"),
                meta: {
                    title: "系统公告"
                }
            },
            {
                path: "sys-notice-edit",
                name: "systemSysNoticeEdit",
                component: () => import("@/view/system/notify/sysNotice/edit"),
                meta: {
                    title: "编辑系统公告"
                }
            },
            {
                path: "wx-official",
                name: "systemWxOfficial",
                component: () => import("@/view/system/wxOfficial"),
                meta: {
                    title: "微公众号"
                }
            },
            {
                path: "wxapp",
                name: "systemWxapp",
                component: () => import("@/view/system/wxapp"),
                meta: {
                    title: "微小程序"
                }
            },
            {
                path: "wx-pay",
                name: "systemWxPay",
                component: () => import("@/view/system/wxPay"),
                meta: {
                    title: "微信支付"
                }
            },
            {
                path: "ali-pay",
                name: "systemAliPay",
                component: () => import("@/view/system/aliPay"),
                meta: {
                    title: "阿里支付"
                }
            },
            {
                path: "sns",
                name: "systemSns",
                component: () => import("@/view/system/sns"),
                meta: {
                    title: "短信配置"
                }
            },
        ]
    }
]