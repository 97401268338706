import Vue from "vue";

import config from "@/config";
import {getUniacid} from "@/api";

const attach = {};
const base = config.api_base + "/sys/attach"

attach.uploadAction = base+"/upload?i=" + getUniacid()
attach.wxPayPemAction = base+"/wx-pay-pem?i=" + getUniacid()
attach.aliPayPemAction = base+"/ali-pay-pem?i=" + getUniacid()
attach.page = (params)=>Vue.axios.post(base + "/page", params)
attach.del = (params)=>Vue.axios.post(base + "/del", params)

// attach.transcode = (params) => Vue.axios.post(base + "/video-transcode", params)

attach.group = {
    del: (params) => Vue.axios.post(base + "/group/del", params),
    add: (params) => Vue.axios.post(base + "/group/add", params),
    all: (params) => Vue.axios.post(base + "/group/all", params),
    move: (params) => Vue.axios.post(base + "/group/move", params),
}


export default attach;