import Vue from "vue";
import config from "@/config";

const areaAgent = {};
const base = config.api_base + "/addons/area-agent"

areaAgent.rule = (params) => Vue.axios.post(base + "/rule", params);
areaAgent.ruleEdit = (params) => Vue.axios.post(base + "/rule-edit", params);

areaAgent.goodsThemeSet = (params)=>Vue.axios.post( base + "/goods-theme-set",params);
areaAgent.goodsThemeRemove = (params)=>Vue.axios.post( base + "/goods-theme-remove",params);


areaAgent.teamSalesRule = (params)=>Vue.axios.post( base + "/team-sales-rule",params);
areaAgent.teamSalesRuleEdit = (params)=>Vue.axios.post( base + "/team-sales-rule-edit",params);
areaAgent.teamSalesRuleDel = (params)=>Vue.axios.post( base + "/team-sales-rule-del",params);

areaAgent.searchUser = (params)=>Vue.axios.post( base + "/search-user",params);
areaAgent.setPartner = (params)=>Vue.axios.post( base + "/set-partner",params);
areaAgent.setArea = (params)=>Vue.axios.post( base + "/set-area",params);


export default areaAgent;