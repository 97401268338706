export default [
    {
        path: "knowledge",
        component: () => import("@/view/addons/knowledge/index"),
        redirect: "/addons/knowledge/course",
        name: "addonsKnowledge",
        meta: {
            title: "知识付费"
        },
        children: [
            {
                path: "teacher",
                name: "addonsKnowledgeTeacher",
                component: () => import("@/view/addons/knowledge/teacher/index"),
                redirect: "/addons/knowledge/teacher/list",
                meta: {
                    title: "讲师管理"
                },
                children: [
                    {
                        path: "list",
                        name: "addonsKnowledgeTeacherList",
                        component: () => import("@/view/addons/knowledge/teacher/list"),
                        meta: {
                            title: "讲师列表"
                        },
                    },
                    {
                        path: "edit",
                        name: "addonsKnowledgeTeacherEdit",
                        component: () => import("@/view/addons/knowledge/teacher/edit"),
                        meta: {
                            title: "编辑讲师信息"
                        },
                    },
                    {
                        path: "award",
                        name: "addonsKnowledgeTeacherAward",
                        component: () => import("@/view/addons/knowledge/teacher/award"),
                        meta: {
                            title: "讲师佣金明细"
                        },
                    },
                ]
            },
            {
                path: "conf",
                name: "addonsKnowledgeConf",
                component: () => import("@/view/addons/knowledge/conf"),
                meta: {
                    title: "基础配置"
                }
            },
            {
                path: "cate",
                name: "addonsKnowledgeCate",
                component: () => import("@/view/addons/knowledge/cate"),
                meta: {
                    title: "课程分类"
                }
            },
            {
                path: "course",
                name: "addonsKnowledgeCourse",
                component: () => import("@/view/addons/knowledge/course/index"),
                meta: {
                    title: "课程管理"
                },
                redirect: "/addons/knowledge/course/list",
                children: [
                    {
                        path: "list",
                        name: "addonsKnowledgeCourseList",
                        component: () => import("@/view/addons/knowledge/course/list"),
                        meta: {
                            title: "课程列表"
                        },
                    },
                    {
                        path: "edit",
                        name: "addonsKnowledgeCourseEdit",
                        component: () => import("@/view/addons/knowledge/course/edit"),
                        meta: {
                            title: "编辑课程信息"
                        },
                    },
                    {
                        path: "apply",
                        name: "addonsKnowledgeCourseApply",
                        component: () => import("@/view/addons/knowledge/course/apply"),
                        meta: {
                            title: "报名列表"
                        },
                    },
                    {
                        path: "column",
                        name: "addonsKnowledgeCourseColumn",
                        component: () => import("@/view/addons/knowledge/course/column"),
                        meta: {
                            title: "章节管理"
                        },
                    },
                    {
                        path: "column-edit",
                        name: "addonsKnowledgeCourseColumnEdit",
                        component: () => import("@/view/addons/knowledge/course/column-edit"),
                        meta: {
                            title: "章节编辑"
                        },
                    },
                ]
            }
        ]
    }
]